import PropTypes from 'prop-types'

import Button from '../../../../../shared_components/mui_base_components/button/Button'
import Tooltip from '../../../../../shared_components/mui_base_components/tooltip/ToolTip'

import './manageLinkTooltip.scss'

const ManageLinkTooltip = props => {
  const {
    dismissTooltip,
    onClose,
    onOpen,
    sadTooltipText,
    showTooltip
  } = props

  if (!showTooltip) return null

  return (
    <Tooltip
      className='manage-link-tooltip'
      disableHoverListener
      disableScreen={true}
      onClose={onClose}
      onOpen={onOpen}
      open={true}
      slotProps={{
        popper: {
          disablePortal: true
        }
      }}
      title={
        <div className='tooltip-content'>
          <p className='tooltip-text'>{sadTooltipText}</p>
          <Button onClick={dismissTooltip}>Got it</Button>
        </div>
      }
    />
  )
}

ManageLinkTooltip.propTypes = {
  dismissTooltip: PropTypes.func,
  onClose: PropTypes.func,
  onOpen: PropTypes.func,
  sadTooltipText: PropTypes.string,
  showTooltip: PropTypes.bool
}

export default ManageLinkTooltip
